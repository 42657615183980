
  /* Mobile & Tablet */
@media screen and (max-width : 1023px) {
  .div{
    text-align: center;
    .loader { margin-top: 20vw;
      border: 0.2vw solid #FFF;
      width: 30vw;
      height: 30vw;
      background: #04aa6d;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      animation: rotation 2s linear infinite;
    }
    .loader::after {
      justify-content: center;
      align-items: center;
      content: '';  
      box-sizing: border-box;
      position: absolute;
      left: 50%;
      top: 50%;
      border: 15vw solid;
      border-color: transparent #FFF;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
    
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } 
    .capNhatDuLieu{
      font-size: 5vw;
      font-weight: 600;
    }
  }
  
  

}

/* PC*/
@media screen and (min-width : 1024px) {
   
}